import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  IRCompressor,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  Pond,
  ValveConnect,
  AftercoolerFan,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowCustom,
  PumpsRow,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  InfoControl,
  MultiSetpointControl,
} from 'isno/lib/components/controls';

import { set, startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function CompressorScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  if (!props.locations?.locations?.[1]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label="Compressors">
            <InfoControlRow>
              <MultiSetpointControl
                title="AfterCooler PID"
                labels={['Auto Setpoint', 'Manual Setpoint']}
                statistics={[
                  `${locationEquipment1?.['AC PID']?.ioValues?.auto_setpoint?.value}`,
                  `${locationEquipment1?.['AC PID']?.ioValues?.manual_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment1?.['AC PID']?.ioValues?.auto_setpoint?.ioValueType?.units}`,
                  `${locationEquipment1?.['AC PID']?.ioValues?.manual_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment1?.['AC PID']?.ioValues?.auto_setpoint?.id}`,
                  `${locationEquipment1?.['AC PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                max={['500', '100']}
                min={['0', '0']}
                precision={[0, 0]}
                stats
                // statsBefore
                statsStats={[locationEquipment1?.['AC PID']?.ioValues?.auto_enabled?.value ? 'Auto' : 'Manual']}
                statsLabels={['']}
                statsColors={[locationEquipment1?.['AC PID']?.ioValues?.auto_enabled?.value ? theme.onGreen : theme.alarm]}
              />
              <InfoControl
                title="Defrost Bypass"
                statistic={locationEquipment1?.Other?.ioValues?.defrost_bypass_disable_enable?.value ? 'Enabled' : 'Disabled'}
                color={locationEquipment1?.Other?.ioValues?.defrost_bypass_disable_enable?.value ? theme.onGreen : theme.alarm}
                label=""
                button1Text="Enable"
                button2Text="Disable"
                button1WriteValue={1}
                button2WriteValue={0}
                button1WriteId={locationEquipment1?.Other?.ioValues?.defrost_bypass_disable_enable?.id}
                button2WriteId={locationEquipment1?.Other?.ioValues?.defrost_bypass_disable_enable?.id}
                setIOValue={props.setIOValue}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment1?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment1?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[1])} // plcId 1: Primary
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
            </InfoControlRow>
            <PumpsRow>
              <IRCompressor
                viewOnly
                atlasImg
                first
                pipeTypeBelow={[false, false, false]}
                pipeTypeAbove={[false, false, false]}
                compressorData={locationEquipment1.C1}
                location={props.locations?.locations?.[1]?.name}
                controlPositionTop="-50px"
                controlPositionLeft="8px"
                pipeColorCenter={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationEquipment1?.C1?.id,
                    label: 'Status',
                    status: locationEquipment1?.C1?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.C1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.C1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.C1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.C1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment1?.C1?.ioValues?.oil_temperature?.value,
                    locationEquipment1?.C1?.ioValues?.oil_pressure?.value,
                    locationEquipment1?.C1?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment1?.C1?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment1?.C1?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment1?.C1?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment1?.C1?.ioValues?.oil_temperature?.id,
                    locationEquipment1?.C1?.ioValues?.oil_pressure?.id,
                    locationEquipment1?.C1?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment1?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment1?.C1?.ioValues?.system_pressure?.value,
                    locationEquipment1?.C1?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment1?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment1?.C1?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment1?.C1?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment1?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment1?.C1?.ioValues?.system_pressure?.id,
                    locationEquipment1?.C1?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <div css={css`width: 20px; height: 100%;`}>
                <PipePlaceholder minWidth="0px" horizontalCenter color={theme.pipeColors.air} />
              </div>
              <IRCompressor
                viewOnly
                atlasImg
                pipeTypeBelow={[false, false, false]}
                pipeTypeAbove={[false, false, false]}
                compressorData={locationEquipment1.C1}
                location={props.locations?.locations?.[1]?.name}
                controlPositionTop="-50px"
                controlPositionLeft="8px"
                pipeColorCenter={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                readValues={[1, 1, 1, 1]}
                writeValues={[1, 1, 1, 1]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationEquipment1?.C3?.id,
                    label: 'Status',
                    status: locationEquipment1?.C3?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.C3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.C3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment1?.C3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment1?.C3?.ioValues?.oil_temperature?.value,
                    locationEquipment1?.C3?.ioValues?.oil_pressure?.value,
                    locationEquipment1?.C3?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment1?.C3?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment1?.C3?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment1?.C3?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment1?.C3?.ioValues?.oil_temperature?.id,
                    locationEquipment1?.C3?.ioValues?.oil_pressure?.id,
                    locationEquipment1?.C3?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment1?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment1?.C3?.ioValues?.system_pressure?.value,
                    locationEquipment1?.C3?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment1?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment1?.C3?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment1?.C3?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment1?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment1?.C3?.ioValues?.system_pressure?.id,
                    locationEquipment1?.C3?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <div css={styles.flexRow}>
                <div css={css`width: 0px;`}>
                  <AftercoolerFan
                    auto
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-46px"
                    controlPositionLeft="10px"
                    fanData={{
                      ...locationEquipment1?.AC,
                      state: locationEquipment1?.AC?.ioValues?.run_contact?.value,
                      fanColor: locationEquipment1?.AC?.ioValues?.run_contact?.value ? 'green' : 'red',
                    }}
                    IOValueKeys={{
                      start: 'manual_start',
                      stop: 'manual_stop',
                      autoStart: 'auto_start',
                      autoStop: 'auto_stop',
                      started: 'manual_start',
                      stopped: 'manual_stop',
                    }}
                    stateColorKey={{
                      0: `${theme.secondary}`,
                      1: `${theme.onGreen}`,
                      2: `${theme.warning}`,
                      3: `${theme.alarm}`,
                    }}
                    statuses={[
                      {
                        id: locationEquipment1?.AC?.ioValues?.defrosting?.id,
                        status: locationEquipment1?.AC?.ioValues?.defrosting?.value,
                        statusKey: {
                          0: '',
                          1: 'Defrosting',
                        },
                        statusColorKey: {
                          0: '', // These aren't the "named" colors but I like them for this use case
                          1: theme.local,
                        },
                      },
                    ]}
                    stats={[
                      {
                        stat: locationEquipment1?.AC?.ioValues?.[IOValueKeys.amps]?.value,
                        label: locationEquipment1?.AC?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment1?.AC?.ioValues?.[IOValueKeys.speed]?.value,
                        label: locationEquipment1?.AC?.ioValues?.[IOValueKeys.speed]?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment1?.AC?.ioValues?.temperature?.value,
                        label: locationEquipment1?.AC?.ioValues?.temperature?.ioValueType?.units,
                        precision: 1,
                      },
                    ]}
                  />
                </div>
                <PipePlaceholder horizontalCenter color={theme.pipeColors.air} />
              </div>

              <div css={styles.flexRow}>
                <PipePlaceholder minWidth="0px" horizontalCenter color={theme.pipeColors.air} />
                <div css={css`display: flex; flex-direction: column; justify-content: center; height: 100%;`}>
                  <Statistic
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.value}
                    border
                    label={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
                  />
                </div>
                <PipePlaceholder minWidth="0px" capAfter capLabel="Discharge" capLabelLeft="-66px" capLabelTop="-20px" horizontalCenter color={theme.pipeColors.air} />
              </div>
            </PumpsRow>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexCol: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    flexRow: css`
      display: flex;
      flex-direction: row;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

CompressorScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

CompressorScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(CompressorScreen);
