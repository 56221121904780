import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  IRCompressor,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  Pond,
  ValveConnect,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowCustom,
  PumpsRow,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  InfoControl,
  MultiSetpointControl,
  MultiInfoButton,
} from 'isno/lib/components/controls';

import { set, startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function PumpScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  if (!props.locations?.locations?.[1]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label="Pumphouse">
            <InfoControlRow>
              <MultiSetpointControl
                title="Pump PID"
                labels={['Auto Setpoint', 'P1 Setpoint', 'P2 Setpoint']}
                statistics={[
                  `${locationEquipment1?.['Pump PID']?.ioValues?.auto_setpoint?.value}`,
                  `${locationEquipment1?.['Pump PID']?.ioValues?.p1_manual_setpoint?.value}`,
                  `${locationEquipment1?.['Pump PID']?.ioValues?.p2_manual_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment1?.['Pump PID']?.ioValues?.auto_setpoint?.ioValueType?.units}`,
                  `${locationEquipment1?.['Pump PID']?.ioValues?.p1_manual_setpoint?.ioValueType?.units}`,
                  `${locationEquipment1?.['Pump PID']?.ioValues?.p2_manual_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment1?.['Pump PID']?.ioValues?.auto_setpoint?.id}`,
                  `${locationEquipment1?.['Pump PID']?.ioValues?.p1_manual_setpoint?.id}`,
                  `${locationEquipment1?.['Pump PID']?.ioValues?.p2_manual_setpoint?.id}`,
                ]}
                max={['1000', '100', '100']}
                min={['0', '0', '0']}
                precision={[0, 0, 0]}
                stats
                // statsBefore
                statsStats={[locationEquipment1?.['Pump PID']?.ioValues?.auto_manual?.value ? 'Manual' : 'Auto']}
                statsLabels={['']}
                statsColors={[locationEquipment1?.['Pump PID']?.ioValues?.auto_manual?.value ? theme.alarm : theme.onGreen]}
              />
              <InfoControl
                label=""
                title="Charging"
                statistic={locationEquipment1?.Other?.ioValues?.charging?.value ? 'Charging' : 'Not Charging'}
                color={locationEquipment1?.Other?.ioValues?.charging?.value ? theme.onGreen : ''}
                button1Text="Start"
                button2Text="Stop"
                button1WriteId={locationEquipment1?.Other?.ioValues?.charge_start?.id}
                button2WriteId={locationEquipment1?.Other?.ioValues?.charge_stop?.id}
                setIOValue={props.setIOValue}
              />
              <InfoControl
                label=""
                title="Lead VFD"
                statistic={locationEquipment1?.Other?.ioValues?.lead_vfd_setpoint?.value === 2 ? 'P2'
                  : locationEquipment1?.Other?.ioValues?.lead_vfd_setpoint?.value === 1 ? 'P1' : 'None'}
                color={locationEquipment1?.Other?.ioValues?.lead_vfd_setpoint?.value ? theme.onGreen : ''}
                button1Text="P1"
                button2Text="P2"
                button1WriteValue={1}
                button2WriteValue={2}
                button1WriteId={locationEquipment1?.Other?.ioValues?.lead_vfd_setpoint?.id}
                button2WriteId={locationEquipment1?.Other?.ioValues?.lead_vfd_setpoint?.id}
                setIOValue={props.setIOValue}
              />
              <MultiInfoButton
                title="Flow Totals"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment1?.FT?.ioValues?.trip_volume?.value,
                  locationEquipment1?.FT?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment1?.FT?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment1?.FT?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment1?.FT?.ioValues?.trip_volume_reset?.id,
                  locationEquipment1?.FT?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment1?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment1?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[1])} // plcId 1: Primary
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
            </InfoControlRow>
            <PumpsRowMedium>
              <div css={styles.flexRow}>
                <Pump
                  pipeAbove="right"
                  percent
                  controlWidth="122px"
                  controlPositionTop="-57px"
                  auto
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment1?.P1}
                  otherData={locationEquipment1?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  readValues={[1, 1, 1, 1]}
                  writeValues={[1, 1, 1, 1]}
                  IOValueKeys={{
                    started: 'manual_start',
                    stopped: 'manual_stop',
                    start: 'manual_start',
                    stop: 'manual_stop',
                    local: 'auto_start',
                    remote: 'auto_stop',
                    status: 'run_contact',
                    speed: 'speed',
                  }}
                  statuses={[
                    {
                      id: locationEquipment1.P1?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1.P1?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                    {
                      id: locationEquipment1.P1?.ioValues?.lead_vfd?.id,
                      status: locationEquipment1.P1?.ioValues?.lead_vfd?.value,
                      statusKey: {
                        0: '',
                        1: language.leadVFD,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.leadVFD,
                      },
                    },
                  ]}
                  alarms={[
                    locationEquipment1?.P1?.ioValues?.high_seal_temperature,
                    locationEquipment1?.P1?.ioValues?.fault,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment1?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment1?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={css` height: 100%; margin-top: -3px;`}>
                  <Statistic
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.value}
                    border
                    label={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                  />
                </div>
                <PipePlaceholder horizontalAbove minWidth="0px" />
              </div>
              <div css={styles.flexRow}>
                <Pump
                  pipeAbove="middle"
                  percent
                  controlWidth="122px"
                  controlPositionTop="-57px"
                  auto
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment1?.P2}
                  otherData={locationEquipment1?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  readValues={[1, 1, 1, 1]}
                  writeValues={[1, 1, 1, 1]}
                  IOValueKeys={{
                    started: 'manual_start',
                    stopped: 'manual_stop',
                    start: 'manual_start',
                    stop: 'manual_stop',
                    local: 'auto_start',
                    remote: 'auto_stop',
                    status: 'run_contact',
                    speed: 'speed',
                  }}
                  statuses={[
                    {
                      id: locationEquipment1.P2?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1.P2?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                    {
                      id: locationEquipment1.P2?.ioValues?.lead_vfd?.id,
                      status: locationEquipment1.P2?.ioValues?.lead_vfd?.value,
                      statusKey: {
                        0: '',
                        1: language.leadVFD,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.leadVFD,
                      },
                    },
                  ]}
                  alarms={[
                    locationEquipment1?.P2?.ioValues?.high_seal_temperature,
                    locationEquipment1?.P2?.ioValues?.fault,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment1?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment1?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={css` height: 100%; margin-top: -3px;`}>
                  <Statistic
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_water_flow?.value}
                    border
                    label={locationEquipment1?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                  />
                </div>
                <PipePlaceholder horizontalAbove minWidth="0px" />
              </div>
              <div css={styles.flexCol}>
                <div css={css`height: 15px;`}>
                  <PipePlaceholder horizontalAbove capAfter capLabel="To Trails" capLabelTop="15px" capLabelLeft="-60px" />
                </div>
              </div>
            </PumpsRowMedium>
            <PumpsRowCustom height="44px">
              <PipePlaceholder />
              <PipePlaceholder />
              <PipePlaceholder hidden />
            </PumpsRowCustom>
            <PumpsRowCustom height="85px">
              <Pond
                color={theme.pipeColors.water}
                id={`Lake_Info_${locationEquipment1?.id}`}
              >
                <Info
                  title="Pit Level"
                  precision={1}
                  statistic={locationEquipment1?.Other?.ioValues?.pit_level?.value}
                  label={locationEquipment1?.Other?.ioValues?.pit_level?.ioValueType?.units}
                  alertText={(() => {
                    if (locationEquipment1?.Other?.ioValues?.low_pit_level?.value) {
                      return 'Low Pit Level';
                    } else {
                      return null;
                    }
                  })()}
                  alertTop="-23px"
                  alertLeft={locationEquipment1?.Other?.ioValues?.low_pit_level?.value ? '-50px' : '-15px'}
                />
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexCol: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    flexRow: css`
      display: flex;
      flex-direction: row;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

PumpScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

PumpScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PumpScreen);
